// i18next-extract-mark-ns-start capabilities-electrification-hfc

import { LINKS, ELECTRIFICATION_NAV, CAPABILITIES_PRODUCTS_ELECTRIFICATION_FCEV_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import ResourceListing from '@components/views/ResourceListing';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const CapabilitiesHydrogenFuelCellsPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const ignite = getImage(data.ignite.childImageSharp.gatsbyImageData);
	const vectis = getImage(data.vectis.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<h2>Cost effective fuel cell system modelling </h2>

						<p>
							With hydrogen fuel cell technology, developing at a rapid pace, it is essential to assess the system ‘What ifs’ in a fast, efficient
							and cost-effective manner. The simulation toolset is capable of modelling fuel cell systems with a Fuel Cell Systems library;
							allowing the user to quickly investigate different control strategies for balance of plant, including humidity control,
							cooling, and specification of ancillaries especially the sizing the components to give the required performance.
						</p>

						<ul>
							<li>Tackles the challenges of investigating fuel stacks without specific fuel cell expertise</li>

							<li>Built on a Modelica based component library including anode (compressor), cathode (tank), thermal (pump), controllers, sensors,
								and fluid media components</li>

							<li>Simulates the balance of plant, including humidity control, cooling, and component sizing</li>

							<li>Fast model setup and rapid simulation times reducing the need for physical prototype builds</li>
						</ul>

						<p>
							The Fuel Cell toolset consists of 2 modelling options for complete systems and efficient thermal battery pack analysis:
						</p>
					</Trans>

					{ignite && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={ignite}
								alt="IGNITE"
								className="icon-image"
							/>
						</div>
					)}

					<Trans i18nKey="content_2">
						<p>
							IGNITE, is a complete physics-based vehicle systems modelling and simulation package.
							With a library system of building blocks, the user can quickly and accurately model complete fuel cell systems,
							analysing parameters around performance and efficiency.
						</p>
					</Trans>

					{vectis && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={vectis}
								alt="VECTIS"
								className="icon-image"
							/>
						</div>
					)}

					<Trans i18nKey="content_3">
						<p>
							VECTIS, 3D CFD is used to optimise intake manifold design and cooling for maximum thermal performance.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_TechnologyTransition")}
						className="widget__sibling__pages">
						<AssetList>
							{ELECTRIFICATION_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Aside.Widget
						title={t("_Products")}
						className="widget__sibling__pages">
						<AssetList>
							{CAPABILITIES_PRODUCTS_ELECTRIFICATION_FCEV_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>

		</Page>
	);
};

export default CapabilitiesHydrogenFuelCellsPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["capabilities-electrification-hfc", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/capabilities/electrification/Realis-Simulation_1920x1080_FuelCell2_largerframe.jpg" }) {
		...imageBreaker
	}
	ignite: file(relativePath: { eq: "content/logos/IGNITE.png" }) {
		...imageBreaker
	}
	vectis: file(relativePath: { eq: "content/products/vectis/rs_vectis.png" }) {
		...imageBreaker
	}
	resources: allMarkdownRemark(
		filter: {
			fileAbsolutePath: { regex: "/resources/" }
			fields: { language: { eq: $language } }
			frontmatter: { tags: { in: "Fuel cell" } }
		}
		sort: { fields: frontmatter___date, order: DESC }
		limit: 9
	) {
		nodes {
			fields {
				slug
			}
			frontmatter {
				title
				summary
				image {
					...imageStandard
				}
				type
			}
		}
	}
}
`;
